import React from 'react';
import ColoredItem from './ColortItem';

export interface ColoredListProps {
    items: { TITLE: string, CONTENT: string, IMAGE?: string, STICKY?: boolean }[];
}

export interface ColoredListState {

}

class ColoredList extends React.Component<ColoredListProps, ColoredListState> {
    render() {
        const {items} = this.props;
        return (
            <>
                {items && items.map((x, i) => (
                    <ColoredItem
                        key={x.TITLE}
                        index={i + 1}
                        title={x.TITLE}
                        content={x.CONTENT}
                        image={x.IMAGE}
                        sticky={x.STICKY}
                    />
                ))}
            </>
        );
    }
}

export default ColoredList;
