import React from 'react';
import './Feature.scss'

export interface FeatureProps {
    title: string;
    content: string;
    image?: string;
    sticky?: boolean;
    index: number;
}

export interface FeatureState {
    active: boolean;
}

class Feature extends React.Component<FeatureProps, FeatureState> {
    constructor(props: FeatureProps) {
        super(props);
        this.state = {
            active: props.sticky || false,
        };
    }

    render() {
        const {active} = this.state;
        const {title, content, image} = this.props;
        return (
            <div className={`feature ${active ? 'active' : ''}`} onClick={() => this.setState({active: !active})}>
                <div className={'icon'} />
                <span className={'check'}/>
                <div>
                    {title}
                    <img hidden={!image || !active} src={image} />
                    <p className="content" hidden={!active} dangerouslySetInnerHTML={{__html: content}} />
                    <div className="spacer" />
                </div>
            </div>
        );
    }
}

export default Feature
