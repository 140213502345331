import React from 'react';
import './ColoredList.scss'

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

export interface ColordItemProps {
    title: string;
    content: string;
    image?: string;
    sticky?: boolean;
    index: number;
}

export interface ColoredItemState {
    active: boolean;
}

class ColoredItem extends React.Component<ColordItemProps, ColoredItemState> {
    constructor(props: ColordItemProps) {
        super(props);
        this.state = {
            active: props.sticky || false,
        };
    }

    render() {
        const {active} = this.state;
        const {index, title, content, image} = this.props;
        return (
            <div className={`colored-item ${active ? 'active' : ''}`} onClick={() => this.setState({active: !active})}>
                <div className={'icon'} />
                <span className="number">{zeroPad(index, 2)}</span>{title}
                <img hidden={!image || !active} src={image} />
                <p className="content" hidden={!active} dangerouslySetInnerHTML={{__html: content}} />
                <div className="spacer"/>
            </div>
        );
    }
}

export default ColoredItem
