import React from 'react';
import './App.scss';
import LandingPage from './pages/LandingPage';
import { TRANSLATION } from './config/translations';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from 'react-router-dom';
import { Container } from 'react-bootstrap';

interface AppProps {}

interface AppState {
    loading: boolean;
}

class App extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        // @ts-ignore
        document.fonts.ready.then(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const {loading} = this.state;
        return (
            <>
                <Router>
                    <div className="loading" hidden={!loading} />
                    <div className="header"><h1>JUDOJAMA</h1></div>

                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/datenschutz" element={
                            <Container>
                                <div className="dsgvo" dangerouslySetInnerHTML={{__html: TRANSLATION.DSGVO}} />
                                <Link to="/">{TRANSLATION.DSGVO_BACK}</Link>
                            </Container>
                        } />
                    </Routes>

                    <div className="copyright">
                        <img className="phone" src="/phone_judocard.png" alt="" />
                        <h1>{TRANSLATION.TITLE}</h1>
                        <p>{TRANSLATION.COPYRIGHT}</p>
                    </div>
                    <div className="footer">
                        <p><strong>{TRANSLATION.IMPRINT}</strong></p>
                        <p dangerouslySetInnerHTML={{__html: TRANSLATION.IMPRINT_DETAILS}} />
                        <Link to="/datenschutz">{TRANSLATION.DSGVO_LINK}</Link>
                    </div>
                </Router>
            </>

        );
    }
}

export default App;
