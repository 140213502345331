import React from 'react';
import Feature from './Feature';

export interface FeaturesProps {
    items: { TITLE: string, CONTENT: string, IMAGE?: string, STICKY?: boolean }[];
}

export interface FeaturesState {

}

class Features extends React.Component<FeaturesProps, FeaturesState> {

    render() {
        const {items} = this.props;
        return (
            <>
                {items && items.map((x, i) => (
                    <Feature
                        key={x.TITLE}
                        index={i}
                        title={x.TITLE}
                        content={x.CONTENT}
                        image={x.IMAGE}
                        sticky={x.STICKY}
                    />
                ))}
                <div className="feature" />
            </>
        );
    }
}

export default Features;
