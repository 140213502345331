export const TRANSLATION = {
    TITLE: 'JUDOJAMA',
    INFO_TITLE: 'JUDOJAMA VERBANDS-MANAGEMENT',
    INFO_SUBTITLE: '»Alles aus einer Hand«',
    INFO_TEXT: '<strong>Die flexible Lösung für Verbände und Vereine.</strong> Websites, Mitgliederverwaltung, Einladungs-Management, Turnierverwaltung, Ticketsystem, digitale Mitgliedskarte (App) und vieles mehr — wir liefern alles aus einer Hand, maßgeschneidert für Ihre Bedürfnisse. Kontaktieren Sie uns.',
    COMPONENTS_TITLE: 'UNSERE KOMPONENTEN',
    COMPONENTS: [
        {TITLE: 'Verwaltung', CONTENT: 'Ein umfangreiches Verwaltungsinterface sowohl für Bundes- als auch für  Landesverbände und Vereine. Entsprechend der jeweiligen Funktionen einer Person, erhält diese mehr oder weniger Verwaltungsrechte. Eine vollständig rollenbasierte Architeltur. <br/>Von der Turnieranmeldung via App bis hin zur Startgeldabrechnung.'},
        {TITLE: 'Websites/CMS', CONTENT: 'Ein Content Management System (CMS) für den Bundesverband <b>und</b> für alle Vereine <p><br>Automatische Einbindung des Kalender, von Vorstand und Funktionären, Kader, News u.v.a.m.<br>Beispiel-<b>Verbandshomepage:</b> https://www.domain.org <br>Beispiel-<b>Vereinsshomepage:</b> https://mein-verein.domain.org (via Sub-domain)</p>'},
        {TITLE: 'Push-Messages via App', CONTENT: 'Informiere deine Mitglieder in Echtzeit über neue Turniere, ebenso wie über Terminverschiebungen und Corona-Maßnahmen.'},
        {TITLE: 'Leistungsfähige Datenbank', CONTENT: 'Das leistungsfähige Rückgrad unseres EcoSystems ist die PostgreSQL Datenbank'},
        {TITLE: 'Moderne APP', CONTENT: 'Anzeige von News, Turnier-Kalender, Mitglider-/LizenzCard.<br> Dazu vollständige Verwaltung auch via App für iPhone & Android.<br/>Eigener Trainer-Modus.',IMAGE: '/phone_judocard.png'},
        {TITLE: 'Offenes API', CONTENT: 'Offenes Schnittstellen (Swagger-Doc) und Schnittstellen zu Drittsystemen, Eigenes Demo- und Testsystem.'},
       //{TITLE: 'Webshop', CONTENT: 'Mitgliedsbeiträge, Fanartikel'},
        {TITLE: 'Hosting', CONTENT: 'Hosting auf unserern eigenen Servern in einem deutschen Rechenzentrum (Hetznder.de).'},
    ],
    COSTS_TITLE: 'KOSTEN',
    COSTS_CONTENT: ['Montalicher Paketpreis ab*', '€ 790', ''],
    COSTS_FOOTER: '*Basiskosten abhängig vom Paketumfang, zuzüglich Import & Setup und individuellen Anpassungen.',
    FEATURES_TITLE: 'FEATURE-HIGHLIGHTS',
    FEATURES: [
        {TITLE: 'Mitgliederverwaltung', CONTENT: 'Abhängig von deiner Funktion (GeneralsekretärIn, Verbandsadministration, TrainerIn, KampfrichterIn, Obmann/Obfrau, SchriftführerIn usw. verwaltest du deine Mitglieder, Vereine, Ausbildungen und Lizenzen.', IMAGE: '/F175_700.jpeg', STICKY: true},
        {TITLE: 'Vereinsverwaltung', CONTENT: 'Verwalte deine Mitlieder, deinen Verein und deine Veranstaltungen. Lege neue Mitglieder an oder lade sie zu einem Event ein.', STICKY: true},
        {TITLE: 'Audit (wer, was, wann)\n', CONTENT: 'Vollständig DSGVO-Konform und darüber hinaus. Jeder Zugriff auf persönlichen Daten wird protokolliert. Jede Datenänderung ist nachvollziehbar und reversibel.'},
        {TITLE: 'Liga-System\n', CONTENT: 'Umfangreiches Bunde- und Landesligasystem mit Ergebnissen, Tabellen und Wertungen. <br>Ergebnisse können via offener API-Schnittstelle in jedes andere System oder in jede andere App integriert werden',IMAGE: '/Liga_700.jpeg', STICKY: true},
        {TITLE: 'Event- und Einladungs-Management\n', CONTENT: 'Umfangreiches Event Management. Erstelle Wettkämpfe, Trainigs, Veranstaltungen, Prüfungen und zeige diese automatisch im Kalender im Web als auch in der App. Beides sowohl für den Verband als auch für den Verein, wobei Verbands-Events für alle sichtbar sind, Vereins-Events nur auf der Homepage des jeweiligen Vereins zu sehen sind.'},
        {TITLE: 'Verwaltung von Qualifikationen\n', CONTENT: 'Hinterlege Hierarchien von Qualifikationen und erstelle für jeden Sportler automatisch einen Qualifizierungspfad. Lege eine (KYU-)Prüfung im Eventmanagement an. Automatisch wird bei erfolgreicher Teilnahme der neue Grad bei den Sportlern eingetragen und ist sofort in deren JudoCard-App sichtbar!'},
        {TITLE: 'Lizenz-Management\n', CONTENT: 'Egal ob jährlicher Mitgliedsbeitrag, Trainerlizenz oder historische Lizenzen, JUDOJAMA bietet alle Möglichkeiten der umfaßenden Lizenzverwaltung mit autom. Listenerstellung derjeniger, deren Lizenz abzulaufen droht.'},
        {TITLE: 'Elektronische Eintrittskarten\n', CONTENT: 'Keine teuren externen Ticket-Systeme. Erstelle deine Eintrittskarten direkt in JUDOJAMA und verkaufe sie online. Die Eintrittskarten sind direkt in der JudoCard-App sichtbar.'},
        {TITLE: 'Elektronischer Mitgliederpass \n', CONTENT: 'Spare Zeit und Geld und gib jedem Mitglied seinen aktuellen Judopass aufs Smartphone. Eltern verwalten die Pässe für ihre Kinder!'},
        {TITLE: 'Zielgruppen Push-Notification aufs Smartphone\n', CONTENT: 'Kommuniziere mit deinen Mitgliedern direkt und Just-in-Time.'},
    ],
    LETS_GO: 'LET\'S GO!',
    LETS_GO_CONTACT: 'Kontaktieren Sie uns:\n',
    LETS_GO_PHONE: '+43 205 108 525 0',
    LETS_GO_EMAIL: 'office@marksim.org',
    LETS_GO_INFO: 'Dauer einer Projektumsetzung bis zum Start: Ab 6 Wochen, inklusive Datenimport<br>' +
        'Alle Preise netto, in Euro. Angebot gültig ab 1. Februar 2024.',
    COPYRIGHT: 'MARKSIM® SERVICES GMBH. All rights reserved.',
    IMPRINT: 'Impressum',
    IMPRINT_DETAILS: 'MARKSIM SERVICES GMBH<br>' +
        'Oberdorfstrasse 9/1/15, 1220 Wien<br>' +
        'Firmenbuch: FN461691h<br>' +
        '+43-205 108 525 0  |  office@marksim.org',
    DSGVO: '<p><h3>DSGVO</h3>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website. Diese Seite speichert keine personenbezogenen Daten.</p><p><h3>Kontakt mit uns</h3> Wenn Sie per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter. </p><p><h3>Cookies</h3> Unsere Website verwendet KEINE Cookies.</p><p><h3>Web-Analyse</h3>Unsere Website verwendet derzeit KEINE Tools zur Analyse der Weseite (auch nicht Google-Analytics).<br>Der Webserver schreibt zwar ihre IP-Adresse zusammen mit einem Zeitstempel in eine log-Datei, diese wird aber nur zur Fehlersuche ausgewertet. Nach spätestens sechs Monaten ist die IP-Adresse aus dem log wieder verschwunden.</p>',
    DSGVO_LINK: 'Datenschutz',
    DSGVO_BACK: 'Zurück zur Startseite',
}
