import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Features from '../components/Features';
import ColoredList from '../components/ColoredList';
import {TRANSLATION} from '../config/translations';
import './LandingPage.scss'

export interface LandingPageProps {

}

export interface LandingPageState {

}

class LandingPage extends React.Component<LandingPageProps, LandingPageState> {
    constructor(props: LandingPageProps) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <>
                <div className="content">
                    <Container>
                        <Row className="wrapper info">
                            <Col xs={7} md={9}>
                                <h3>{TRANSLATION.INFO_TITLE}</h3>
                                <h4>{TRANSLATION.INFO_SUBTITLE}</h4>
                                <p dangerouslySetInnerHTML={{__html: TRANSLATION.INFO_TEXT}} />
                            </Col>
                            <Col xs={5} md={3}>
                                <img className="phone" src="/phone_judocard.png" />
                            </Col>
                        </Row>
                        <div className="wrapper">
                            <h2>{TRANSLATION.COMPONENTS_TITLE}</h2>
                            <ColoredList items={TRANSLATION.COMPONENTS} />
                        </div>
                        <h2>{TRANSLATION.COSTS_TITLE}</h2>
                        <div className="costs">
                            <p>{TRANSLATION.COSTS_CONTENT[0]}</p>
                            <p className="cbig">{TRANSLATION.COSTS_CONTENT[1]}</p>
                            <p>{TRANSLATION.COSTS_CONTENT[2]}</p>
                            <p className="cfooter">{TRANSLATION.COSTS_FOOTER}</p>
                        </div>
                        <div className="wrapper">
                            <h2>{TRANSLATION.FEATURES_TITLE}</h2>
                            <Features items={TRANSLATION.FEATURES} />
                        </div>

                    </Container>
                </div>
                <div className="lets-go">
                    <h1>{TRANSLATION.LETS_GO}</h1>
                    <p>{TRANSLATION.LETS_GO_CONTACT}</p>
                    <p><span className="phone"/>{TRANSLATION.LETS_GO_PHONE}&nbsp;&nbsp;<span className="email"/><a href={`mailto:${TRANSLATION.LETS_GO_EMAIL}`}>{TRANSLATION.LETS_GO_EMAIL}</a></p>
                    <p className="small" dangerouslySetInnerHTML={{__html: TRANSLATION.LETS_GO_INFO}} />
                </div>

            </>

        );
    }
}

export default LandingPage
